import React from "react"
import headerStyles from "./header.module.scss"
import { Link } from "gatsby"

export default function Header(props) {
  return <div className={headerStyles.container}>
    <Link className={headerStyles.title}><h1>Tesla Trips Europe</h1></Link>
    <ul className={headerStyles.navigation}>
      <li><Link className={headerStyles.navigationLink} to="/">Home</Link></li>
      <li><Link className={headerStyles.navigationLink} to="/about">About</Link></li>
    </ul>
  </div>
}