import React from "react"
import Header from "./header"
import Footer from "./footer"
import layoutStyles from "./layout.module.scss"

const Layout = ({ children }) => {

  return (
    <div className={layoutStyles.main}>
      <Header />
      <main>
      { children }
      </main>
      <Footer />
    </div>
  )
}

export default Layout