import React from "react"
import buttonStyles from "./button.module.scss"

export default function Button(props) {
  
  return (
    <a href={props.url} rel="noreferrer" target="_blank" className={buttonStyles.button}>
      {props.label}
    </a>
  )
}
