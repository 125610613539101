import React from "react"
import Layout from "../components/layout"
import Button from "../components/button"

export default function Home() {
  return (
    <Layout>
      <section className="layout-fixed">
        <h1>About</h1>
      </section>
      <section className="layout-fixed">
          <h2>The authors and drivers</h2>
          <img src="/images/about/tesla-model3-pickup.jpeg" alt="Lorenz (left) and Jip (right) next to their new Tesla Model 3 Performance on the day of pick-up at Tesla Zürich."></img>
          <p className="photo-caption">This is us (Lorenz, left and Jip, right) picking up our Tesla Model 3 Performance in December 2019 in Zürich.</p>
      </section>
      <section className="layout-fixed">
        <p>Hi there, we are Jip and Lorenz, the authors of this blog and the proud drivers of a white Tesla Model 3 Performance. Ever since we took the steering wheel - or rather let it go, we were hooked on the Tesla driver experience and lifestyle it comes with. Compared to other cars you can expect owning a Tesla opening doors to new experiences.</p>
        <p>From finding beautiful hotels with free charging facilities to sleeping in the car with camp mode. We want to share our experiences as travel inspiration while giving you insights in consumption and costs. We live in Zürich, Switzerland and thus will be traveling across Europe with our Tesla. Excited for you to join us on the ride!</p>

      </section>
      <section className="layout-fixed">
          <h2>The car</h2>
          <img src="/images/about/IMG_0837.jpg" alt="White Tesla Model 3 Performance in the dunes of Maasvlakte, Netherlands at night."></img>
          <p className="photo-caption">`Stormtrooper`at the dunes of Maasvlakte in the Netherlands.</p>
      </section>
      <section className="layout-fixed">
          <p><Button url="https://teslascope.com/vehicle/jip/Stormtrooper/" label="View on Tesla Scope"/>
        </p>
      </section>
    </Layout>
  )
}